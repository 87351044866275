<template>
    <page-title
        icon="bi-mortarboard-fill"
        title="学校・イベント登録"
    >
        <router-link :to="{name: 'SchoolList'}" class="btn btn-outline-primary">
            <i class="bi bi-reply"></i> 学校・イベント一覧へ
        </router-link>
    </page-title>

    <section class="section">
        <form>
            <div class="form-group col-md-6 mb-3">
                <label>タイプ</label>
                <select class="form-select" required v-model="shooting_type">
                    <option>学校</option>
                    <option>イベント</option>
                </select>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-3">
                    <label v-if="shooting_type === '学校'">学校名</label>
                    <label v-if="shooting_type === 'イベント'">イベント名</label>
                    <input type="text" class="form-control" required>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label>略称名</label>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label v-if="shooting_type === '学校'">学校担当者</label>
                <label v-if="shooting_type === 'イベント'">イベント担当者</label>
                <select class="form-select" required>
                    <option>-- 選択 --</option>
                    <option>片岡</option>
                    <option>江口</option>
                    <option>奥村</option>
                </select>
            </div>
            <div class="form-group mb-3">
                <div class="row">
                    <div class="col-4">
                        <label>都道府県</label>
                        <select class="form-select" required>
                            <option>-- 選択 --</option>
                            <template v-for="pref in prefs" :key="pref">
                                <option>{{ pref.label }}</option>
                            </template>
                        </select>
                    </div>
                    <div class="col-8">
                        <label>住所</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>電話番号</label>
                <div class="input-group">
                    <input type="text" class="form-control">
                    <span class="input-group-text">-</span>
                    <input type="text" class="form-control">
                    <span class="input-group-text">-</span>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>先方窓口</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group col-md-10 mb-5">
                <label>注意事項</label>
                <textarea class="form-control" rows="5"></textarea>
            </div>
            <div>
                <button class="btn btn-primary btn-lg">
                    <i class="bi bi-plus-lg"></i> 登録
                </button>
            </div>
        </form>

    </section>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import Pref from '@/models/enums/pref';

export default {
    name: 'SchoolAdd',
    components: {
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            prefs: Pref.options(),
            shooting_type: '学校',
        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>
